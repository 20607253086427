

import './App.css';

import { BrowserRouter as Router , Routes ,Route } from 'react-router-dom';
import  Home from "./components/Home/Home"
import OnePost from "./components/OnePost/OnePost"
import Navbar from './components/Navbar/Navbar';
import AllBlogs from './components/AllBlogs/AllBlogs';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/OnePost/:slug'  element={<OnePost/>}/>
          <Route path='/AllBlogs' element={<AllBlogs/>}/>
          <Route path='/Contact' element={<Contact/>}/>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
