import React, {useState , useEffect} from 'react'
import  createClient  from '../../Client'
import {motion} from "framer-motion"
import { Container , Row  , Col} from 'reactstrap'
import "./AllBlogs.css"
import { Link } from 'react-router-dom'
import {format} from "date-fns"
import Search from '../Search/Search'
import { fadeIn } from '../motion/Motion'



const Data = () => {

    const [blogs , setBlogs] = useState([]);
  

useEffect(() => {
    createClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        publishedAt,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      body,
      "name": author->name,
      "authorImage": author->image,
      "bio": author->bio,
    } | order(publishedAt desc) `
      )
      .then((data) => setBlogs(data))
     
  }, []);
  if(!blogs) return <span class="loader"></span>
  return (
    <div className='container'>
      <div className="data__main">
        <h1 style={{fontWeight:"bold"}}>All Services</h1>
        <Search/>
        {/* <img src={Strip} alt="" /> */}
      </div>
         <div className="blog__post">
        <Container>
        <Row>
            {blogs && blogs.map((post , idx)=>(
                     <Col lg={4} sm={6} key={idx}>
                     <motion.div
                     initial='hidden'
                     whileInView='show'
                      variants={fadeIn("right" ,"spring", idx * 0.5 , 0.1)}
                     className="blog__data" >
                         <img src={post.mainImage.asset.url} alt="" loading='lazy' />                       
                        <Link className='link' style={{textDecoration:"none" ,color:"#000"}} to={"/OnePost/" + post.slug.current} key={post.slug.current} > <h4>{`${post.title.substring(0,55)}...`}</h4></Link>
                        <p style={{color:"red" , fontWeight:'bold'}}> {format(new Date(post.publishedAt), "dd MMMM yyyy")}</p>
                        {/* <p>{`${post.body[0].children[0].text.substring(0,100)}.....`}</p> */}
                        <Link className='link' style={{textDecoration:"none" ,color:"#000"}} to={"/OnePost/" + post.slug.current} key={post.slug.current} ><span>Get Now</span></Link>
                     </motion.div>
                 </Col>
            ))} 
            </Row>
            <Link style={{textDecoration:"none"}} to="/"> <button>Go Back</button></Link>
        </Container>
        </div>      
    </div>
  )
}

export default Data
