import React, {useEffect , useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import  createClient  from '../../Client'
import BlockContent from '@sanity/block-content-to-react'
import imageUrlBuilder from "@sanity/image-url";
import { Button, Container } from 'reactstrap'
import "./OnePost.css"
import PortableText from "@sanity/block-content-to-react"




const builder = imageUrlBuilder(createClient);
function urlFor(source) {
  return builder.image(source);
}

const OnePost = () => {

const [Post , setPost] = useState(null);
const {slug} = useParams();


useEffect(() => {
    createClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug]);
  if(!Post) return <span class="loader"></span>
  return (
    <div className='text-left'>
    <Container>
    <div className='blog__header'>
      <h2>{`${Post.title}.`}</h2>
        {/* <h4>Written By: <span>{Post.name}</span></h4> */}
        <img src={urlFor(Post.mainImage).url() } alt="" />
    </div>
    
    <div className='blog__body'>
    <PortableText
        projectId="0xzc3zk1"
        dataset="production"
        blocks={Post.body}
    
                               
    />
    </div>
  <Link to='/AllBlogs'>  <Button className='btn btn-danger mt-3 mb-5'>Go Back</Button></Link>
    </Container>
  </div>
  )
}

export default OnePost
