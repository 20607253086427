import React from 'react'
import './Contact.css'
import logo from "../../assets/logo.jpeg"

const Contact = () => {
  return (
    <>
      <h2 className='text-center text-white font' style={{fontWeight:"bold"}}>Need More Services Contact Us</h2>
      <div className="contact__container">
        <div className="contact__form">
            <form action="https://formspree.io/f/mjvdebbv" method='POST'>
                <label>Name</label>
                <input type="text" placeholder='Enter Your Name' required name='name' />
                <label>Email</label>
                <input type="email" placeholder='Enter Your Email' required name='email' />
                <label>Message</label>
                <textarea required name="message" id="" cols="30" rows="4" placeholder='Enter Message'></textarea>
                <button type='submit'>Submit</button>
            </form>
        </div>
        {/* <div className="about__me">
        <div className="about__img">
            <img src={logo} alt="" />
        </div>
        <div className="about__info">
            <h4>IT Technologist</h4>
            <p>Hi There,👋 My name is Obaid Khalid Minhas. I am an Information Technologist and I have
                a lot of experince regarding this particular field. And I am here to help you :)
            </p>
        </div>
        </div> */}
      </div>
    </>
  )
}

export default Contact
