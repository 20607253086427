import React ,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom';
import  createClient  from '../../Client'
import "./Search.css"
import {motion} from "framer-motion"




const Search = () => {

const [Query , setQuery] = useState("");
const [Results , setResults] = useState([]);
const [Search , setSearch] = useState(false);

// const show = ()=>{
//   <Search/>
// }

const handleSearch = async (event) => {
    event.preventDefault();

    const userQuery = event.target.elements.searchInput.value;
    setQuery(userQuery);
    
  if (!userQuery) {
    setResults([]);
    setSearch(true);
    return;
  }
  

    // Perform a search query using the Sanity client library
    const searchResults = await createClient.fetch(
      `*[_type == "post" && title match "${userQuery}"]`,
      { Query: userQuery }
    );

    console.log(searchResults);
    setResults(searchResults);
    setSearch(true);
  };

  return (
    <>
       <div>
      <form onSubmit={handleSearch} className="Search__Container">
        <motion.input whileHover={{boxShadow:"0px 1px 1px 4px red"}} type="text" className='Search__input' name='searchInput' placeholder='Search For Services' value={Query} onChange={(e) => setQuery(e.target.value)} />
        <motion.button whileTap={{scale:"1.1"}} type="submit" className='Search__btn'>Search</motion.button>
      </form>
   
      <ul>
     {Search &&  Results.length === 0 ? (
        <p className='text-white'>Nothing Found</p>
     ):
     Results.map((result) => (
        <motion.p 
        animate={{
          x: 0,
          y: 30,
          scale: 1,
          rotate: 0,
        }}
        transition={{duration:3 , type:'spring'}}
        className='Search__output' key={result._id}>
          <Link  style={{textDecoration:"none" ,color:"#fff"}} to={"/OnePost/" + result.slug.current} key={result.slug.current} ><h6>{`${result.title.substring(0,50)}...`}</h6></Link>
        </motion.p>
      ))
     }
       
      </ul>
    </div>
    </>
  )
}

export default Search
