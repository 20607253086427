import React, { useState } from 'react'
import "./Navbar.css"
import logo from "../../assets/S(2).png"
import { BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import {BiMenuAltRight} from 'react-icons/bi'
import {AiOutlineClose} from "react-icons/ai"
import {BiSearch} from "react-icons/bi"
import { Link } from 'react-router-dom';
import {motion} from "framer-motion"
import Search from '../Search/Search';


const Navbar = () => {

 

const [Toggle , setToggle] = useState(false);

  return (
    <>
      <div className="Navbar">
        <div className="nav__logo">
           <Link to="/"> <img src={logo} alt="" /></Link>
        </div>
        <div className="nav__links">
          <ul >
            <Link  style={{textDecoration:"none" ,color:'#000'}} to="/"><li>Home</li></Link>
            <Link style={{textDecoration:"none" ,color:'#000'}} to="/AllBlogs"><li> Services</li></Link>
            <Link style={{textDecoration:"none" ,color:'#000'}} to="/Contact"><li>Contact</li></Link>
          
          </ul>
          
        

        {/* <div className="nav__social__links">
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsInstagram/></a>
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsFacebook/></a>
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsTwitter/></a>
    
        </div>
      */}
        </div>
        <BiMenuAltRight onClick={() => setToggle(true)} style={{fontSize:"2rem" , cursor:"pointer"}} className="Menu"/>
        {Toggle && (
          <>
             <motion.div className="nav__link"
              animate={{
                x: -20,
                y: 0,
                scale: 1,
                rotate: 0,
              }}
              transition={{duration:1 , type:'spring'}}
             
             >
             <AiOutlineClose className='close__Menu' onClick={()=>setToggle(false)}/>
          <ul >
            <Link  onClick={()=>setToggle(false)} style={{textDecoration:"none" ,color:'#000'}} to="/"><li>Home</li></Link>
            <Link  onClick={()=>setToggle(false)} style={{textDecoration:"none" ,color:'#000'}} to="/AllBlogs"><li>Services</li></Link>
            <Link  onClick={()=>setToggle(false)} style={{textDecoration:"none" ,color:'#000'}} to="/Contact"><li>Contact</li></Link>
          </ul>
          
        

        {/* <div className="nav__social__link">
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsInstagram/></a>
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsFacebook/></a>
            <a href="https://myportfoliodev.pages.dev/#Works" target="_blank" ><BsTwitter/></a>
    
        </div> */}
     
        </motion.div>
          </>
        )}
      </div>
    </>
  )
}

export default Navbar
