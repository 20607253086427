import React, {useEffect , useState} from 'react'
import './Home.css'
import createClient  from '../../Client'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import Navbar from '../Navbar/Navbar'
import Data from '../Data/Data'

const Home = () => {

// const [blogs , setBlogs] = useState(null);

// useEffect(() => {
//     createClient
//       .fetch(
//         `*[_type == "post"]{
//         title,
//         slug,
//         mainImage{
//           asset->{
//           _id,
//           url
//         }
//       },
//       body,
//       "name": author->name,
//     }`
//       )
//       .then((data) => setBlogs(data))
     
//   }, []);


  return (
    <>
   
      {/* <h2>My ALL Blogs</h2>
      {blogs && blogs.map((post , idx)=>(
       <div className='container' key={idx}> {post.title} 
       
       <img src={post.mainImage.asset.url} alt="" />
       {post.name}
       </div>
       
      ))} */}
      <Data/>
    
    </>
  )
}

export default Home
